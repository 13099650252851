















































































































import {
  CommonRulesFields,
  ExtraSettingsMenuItems,
  BaseSpecialSettingFields, StrictModeFields
} from '@/views/chat/filters/FilterTypes'
import CommonRulesSettings from '@/views/chat/filters/components/SettingGroups/CommonRulesSettings.vue'
import VirtualNewSettings from '@/views/chat/filters/components/Fields/VirtualNewSettings.vue'
import CommonSettings from '@/views/chat/filters/components/SettingGroups/CommonSettings.vue'
import FilterLevels from '@/views/chat/filters/components/FilterLevels.vue'
import { VirtualNewFields } from '@/views/chat/filters/FilterTypes'
import chatConfig from '@/mixins/chatConfig'
import IgnoreCaption from '@/views/chat/filters/components/Fields/IgnoreCaption.vue'
import StrictMode from '@/views/chat/filters/components/Fields/StrictMode.vue'
import HandleUserBio from '@/views/chat/filters/components/Fields/HandleUserBio.vue'
import FilterViewMixin from '@/views/chat/filters/include/FilterViewMixin'
import { InputSetups } from '@/mixins/input-setups'
import SectionWrapper from '../../components/SectionWrapper.vue'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'

@Component({
  components: {
    HandleUserBio,
    IgnoreCaption,
    CommonRulesSettings,
    VirtualNewSettings,
    CommonSettings,
    FilterLevels,
    StrictMode,
    SectionWrapper
  },
  data() {
    return {
      ExtraSettingsMenuItems,
      CommonRulesFields,
      VirtualNewFields,
      BaseSpecialSettingFields,
      StrictModeFields
    }
  },
  mixins: [ chatConfig ]
})
export default class SelfLinksView extends Mixins<UseFields, InputSetups, FilterViewMixin>(UseFields, InputSetups, FilterViewMixin) {
}
